window.loadRefererrScript = () => {
    var ref = document.referrer;
    var url = window.location.pathname;
    if (url.toLowerCase().indexOf("find-a-doctor") > -1) {
        if (ref && ref.toLowerCase().indexOf("/hospital-network/") > -1) {
            var el = document.createElement('a');
            el.href = ref;
            var path = el.pathname
            var temp = path.split("/hospital-network/");
            var hospitalSlug = temp[1];
            hospitalSlug = hospitalSlug.split("/")[0];
            // hospitalSlug = hospitalSlug.replace(/\//gi,"");

            window.location.href = "/find-a-doctor?hospital%5B%5D=" + hospitalSlug

        }
        if (ref && ref.toLowerCase().indexOf("/our-specialities/") > -1) {
            var el = document.createElement('a');
            el.href = ref;
            var path = el.pathname
            var temp = path.split("/our-specialities/");
            var specialitySlug = temp[1];
            specialitySlug = specialitySlug.split("/")[0];
            // specialitySlug gi= specialitySlug.replace(/\//gi,"");
            window.location.href = "/find-a-doctor?speciality%5B%5D=" + specialitySlug
        }



    }
};

window.loadRefererrScript();
